import React from 'react'
import clsx from 'clsx'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import i18n from '../../i18n'

import * as styles from './footer.module.scss'
import { FiInstagram, FiLinkedin, FiYoutube } from 'react-icons/fi'

import {
  footerMenuLinks,
  MenuItemsInterface,
  FooterItemsInterface,
} from '../../utils/commonLinks'
import robertyLogo from '../../assets/robertyAutomation-white.svg'
import iconDoc from '../../assets/footer/icon-doc.png'
import iconLink from '../../assets/footer/icon-link.png'

interface FooterProps {
  largeFooter?: boolean
}

export const Footer: React.FC<FooterProps> = ({ largeFooter }) => {
  const utils = [
    {
      icon: iconDoc,
      title: 'Central de Documentações',
      copy: 'Tire suas dúvidas sobre RPA e principalmente sobre todas as integrações e funcionalidades do Roberty.',
      link: 'Acessar documentação',
      href: 'https://docs.roberty.app/',
    },
    {
      icon: iconLink,
      title: 'Roberty na Midia',
      copy: 'Conheça todos os nossos canais sociais, venha interagir com o nosso time, deixe suas opiniões.',
      link: 'Saiba mais',
      href: 'https://linktr.ee/robertyautomation',
    },
  ]

  return (
    <footer
      className={clsx(styles.footer, {
        [styles.largeFooter]: largeFooter,
      })}
    >
      <div className="container">
        <div className="row g-4">
          {utils.map((item, key) => (
            <div className="col-12 col-md-6 my-2 my-md-0" key={item.title}>
              <div
                className={clsx(
                  styles.infoCard,
                  key === 0 ? styles.cyan : styles.rose
                )}
              >
                <img src={item.icon} alt="Documents" />
                <div className={styles.info}>
                  <span>{item.title}</span>
                  <p>{item.copy}</p>
                  <a href={item.href} target="_blank" rel="noopener noreferrer">
                    {item.link}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row py-4 py-lg-5">
          <div className="col-12">
            <hr className={styles.divider} />
          </div>
        </div>

        <div className="row my-3">
          <div className="col-12">
            <div className={styles.footerItems}>
              <div className={styles.logo}>
                <img src={robertyLogo} title="Roberty Automation" />
              </div>
              <div className={styles.links}>
                {footerMenuLinks.map((link: FooterItemsInterface, index) => (
                  <div className={styles.linkItem} key={index}>
                    <strong>{link.item}</strong>
                    <ul>
                      {link.links.map((item: MenuItemsInterface, keyItem) => (
                        <li key={keyItem}>
                          {item.isFunction ? (
                            <a
                              target='_blank'
                              rel='noreferrer'
                              href='https://meetings.hubspot.com/fabio-estofoque?uuid=e29c7862-ddf2-4995-9c50-c0bc3ca27207'>
                              {item.text}
                            </a>
                          ) : item.sameOrigin ? (
                            <AnchorLink to={item.href}>{item.text}</AnchorLink>
                          ) : (
                            <a
                              href={item.href}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.text}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={clsx(styles.copyright, 'row')}>
          <div className="col">
            <small>{i18n[`common.copyright`]}</small>
          </div>
          <div>
            <ul className={styles.socialList}>
              <li>
                <a
                  href="https://www.instagram.com/robertyautomation/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/robertyautomation/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiLinkedin />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/playlist?list=PLVNJypVCHdWsESiuvmR5mKzxCquFJ6e77"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiYoutube />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
